import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import BreadcrumbLayerElement from './BreadcrumbLayerElement'
import { BreadcrumbLayer } from '../../types'

export interface BreadcrumbProps {
  layers: BreadcrumbLayer[]
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { t } = useTranslation('translation')

  const layers: BreadcrumbLayer[] = []

  props.layers.map((layer: BreadcrumbLayer) => {
    layers.push(layer)
  })

  const lastLayer = layers.pop()

  if (layers.length == 0 || typeof lastLayer === 'undefined') {
    return <></>
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col d-none d-md-block">
            <Breadcrumbs aria-label="breadcrumb">
              {layers.map((layer: BreadcrumbLayer, i) => (
                <BreadcrumbLayerElement
                  i={i}
                  key={'breadcrumblayer_' + i}
                  layer={layer}
                />
              ))}

              <Typography color="textPrimary">
                {t(lastLayer.name).toUpperCase()}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
    )
  }
}
