import React from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

export interface FluidImageProps {
  title: string
  imageUrl: string
  link?: string
  className?: string
}

const FluidImage = (options: FluidImageProps) => {
  const image: IGatsbyImageData = {
    layout: 'fullWidth',
    width: 500,
    height: 500,
    backgroundColor: 'white',
    images: {
      fallback: {
        sizes: '(max-width: 500px) 100vw, 500px',
        src: options.imageUrl,
        srcSet: options.imageUrl + ' 500w',
      },
      sources: [
        {
          sizes: '(max-width: 500px) 100vw, 500px',
          srcSet: options.imageUrl + ' 500w',
          type: 'image/jpeg',
        },
      ],
    },
    placeholder: {
      fallback:
        'data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/wAALCAAGAAYBAREA/8QAFAABAAAAAAAAAAAAAAAAAAAACf/EAB0QAAICAQUAAAAAAAAAAAAAAAECAxEEAAUTITH/2gAIAQEAAD8AT7CidN6zJOeRkdRSMxIU9XQ8Gv/Z',
    },
  }

  const handleClick = () => {
    if (typeof options.link !== 'undefined') {
      navigate(options.link)
    }
  }

  let className = ''

  if (typeof options.link !== 'undefined') {
    className = 'clickableLink'
  }

  return (
    <div className={className} onClick={handleClick}>
      <GatsbyImage
        alt={options.title}
        className={options.className}
        image={image}
        title={options.title}
      />
    </div>
  )
}

export default FluidImage
