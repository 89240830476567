import React from 'react'
import { TileElement } from '../../../types'
import { Grid } from '@mui/material'
import { useArtboxONEStyles } from '../../../utils/Style'
import Tile from './Tile'
import { getHash } from '../../../utils/Crypto'

export interface TileGridProps {
  elements: TileElement[]
}

export default function TileGrid(props: TileGridProps) {
  const classes = useArtboxONEStyles()
  const elements = props.elements

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {elements.map((element: TileElement) => (
          <Tile element={element} key={'tilegrid_' + getHash(element)} />
        ))}
      </Grid>
    </div>
  )
}
